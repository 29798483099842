import React, { useState } from 'react';

import Layout from './layout.js';
import DynamicLink from 'components/DynamicLink';
import CircleSvg from 'assets/svg/circle.svg';
import OvalSvg from 'assets/svg/oval.svg';
import DiamondSvg from 'assets/svg/diamond.svg';
import FourPointStar from 'assets/svg/four-point-star.svg';
import SixPointStar from 'assets/svg/six-point-star.svg';

const HomePage = () => {
  const [hasCopied, setHasCopied] = useState(false);

  const copyEmail = () => {
    const dummy = document.createElement('textarea');
    document.body.appendChild(dummy);
    dummy.value = 'caitlinwang97@gmail.com';
    dummy.select();
    dummy.setSelectionRange(0, 99999); /*For mobile devices*/

    document.execCommand('copy');
    document.body.removeChild(dummy);

    setHasCopied(true);
  };

  const asciiComputerStr = `<pre>             
              .__________________________.
             |  ________________________  |
 ~           | |                        | |
      +      | |   welcome!             | |
.-‘*~.       | |                        | |
      \`-._.= | |         .’’,’’.        | | 
  -*.        | |         :     :        | | 
.    \`-~.*"  | |          ‘. .’         | |
             | |            ‘           | |
     :       | |________________________| |
  +          .____________________________.
             \\                             \\
              \\ \\-\\-\\-\\-\\-\\-\\-\\-\\-\\-\\-\\-\\-\\ \\
               \\ \\-\\-\\-\\-\\-\\-\\-\\-\\-\\-\\-\\-\\-\\ \\
                \\_____________________________\\
 </pre>`;

  const asciiMountainStr = `<pre>             
    *         -.          _ 
  .            _        "   "   :       *
           *  / \\      (     )         
         _   /   \\.__   " _ " 
  *   _/   \\/   ^   _\\          _/\`\\      .
     /      \\  _/\\_/  \\        /    \\
    /      / \\/    __   \\__  .’ ^.   ==
   /\\/\`\\  / \` \\  _/  \\     \`/         \\
  /     \\/     \\/     \\     / .-‘    +  \\_
 /  .-         /  :’    \\_./              \\
/___ .=~ ____ /-=~=-___ __/.-\`.__-\`  ___=-=\\

  </pre>`;

  return (
    <Layout>
      <div className="home__wrapper">
        <div className="header__topper">
          <DynamicLink className="header__name" to="/">
            Caitlin Wang —{' '}
          </DynamicLink>
          <span className="header__description">
            I'm a software engineer living in Brooklyn, NY. I currently
            build thoughtful, accessible websites and products at{' '}
            <DynamicLink to="https://upstatement.com">Upstatement</DynamicLink>.
          </span>
        </div>
        <div className="tile-grid">
          <DynamicLink className="tile tile__shape tile__circle" to="/work">
            <>
              <CircleSvg />
              <span className="tile__label">Work Archive</span>
            </>
          </DynamicLink>
          <DynamicLink
            className="tile tile__shape tile__four-point"
            to="/about">
            <>
              <FourPointStar />
              <span className="tile__label">About</span>
            </>
          </DynamicLink>
          <div
            className="tile ascii tile__computer"
            dangerouslySetInnerHTML={{ __html: asciiComputerStr }}></div>
          <div className="tile tile__ovals">
            <DynamicLink
              className="tile__shape oval"
              to="https://github.com/ctlnwng">
              <>
                <OvalSvg />
                <span className="tile__label">GitHub</span>
              </>
            </DynamicLink>
            <DynamicLink
              className="tile__shape oval"
              to="https://www.are.na/caitlin-wang">
              <>
                <OvalSvg />
                <span className="tile__label">Are.na</span>
              </>
            </DynamicLink>
          </div>
          <div
            className="tile ascii tile__mountains"
            dangerouslySetInnerHTML={{ __html: asciiMountainStr }}></div>
          <DynamicLink
            className="tile tile__shape tile__six-point"
            to="/photoboard">
            <>
              <SixPointStar />
              <span className="tile__label">???</span>
            </>
          </DynamicLink>
          <DynamicLink
            className="tile tile__shape tile__diamond"
            to="mailto:caitlinwang97@gmail.com">
            <>
              <DiamondSvg />
              <span className="tile__label">Email</span>
            </>
          </DynamicLink>
        </div>
      </div>
    </Layout>
  );
};

export default HomePage;
